
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonBackButton, IonButtons, IonInput, IonSelect, IonSelectOption  } from '@ionic/vue';


export default  {
    name: 'RollOutDimensionCalculator',
    components: { IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonBackButton, IonButtons, IonInput, IonSelect, IonSelectOption  },
    data() {
        return {
            lengthOriginal: 20,
            widthOriginal: 10,
            thicknessOriginal: 0.5,
            length: 20,
            width: 10,
            thickness: 0.5,
            wantedDimension: "length",
        }
    },
    computed: {

    },
    methods: {

    }
}
